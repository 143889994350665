<template>
  <v-container
    fluid
    id="regular-table"
  >
    <v-form
      ref="form"
      v-model="is_valid"
    >
      <h4 class="text-h4 font-weight-medium my-4 ms-4">
        {{ $t("stockAdjustments.details") }}
      </h4>
      <div
        class="d-flex flex-column flex-md-row"
        style="gap: 150px"
      >
        <div
          class="flex-grow-1 my-4 ms-4"
          style="max-width: 700px"
        >
          <v-text-field
            outlined
            clearable
            dense
            :label="$t('stockAdjustments.inventory_number')"
            v-model="stockAdjustment.inventory_number"
            @blur="handleUpdate"
          />
          <v-autocomplete
            outlined
            dense
            :label="$t('stockAdjustments.hub')"
            item-value="id"
            item-text="name"
            :items="hubs"
            v-model="stockAdjustment.hub_id"
            :rules="[rules.required]"
            @change="handleWarehouseChange"
            :search-input.sync="cleanList"
          />
        </div>
        <div
          div
          class="flex-grow-1 my-4"
          style="max-width: 700px"
        >
          <v-text-field
            outlined
            clearable
            dense
            :label="$t('stockAdjustments.note')"
            v-model="stockAdjustment.note"
            :rules="[rules.required]"
            @blur="handleUpdate"
          />
        </div>
      </div>
    </v-form>
    <v-card class="mt-n1">
      <v-toolbar
        dark
        tabs
        flat
        color="#5E2FBE"
        class="hidden-xs-only"
      >
        <template>
          <v-tabs v-model="tabs">
            <v-tab href="#product">
              <v-icon>mdi-format-list-bulleted</v-icon>&nbsp;
              {{ $t("stockAdjustments.product_list") }}
            </v-tab>

            <v-tab href="#cart">
              <v-badge
                v-if="stockAdjustment.products?.length"
                color="green"
                :content="stockAdjustment.products?.length"
              >
                <v-icon>mdi-cart</v-icon>&nbsp;{{
                  $t("stockAdjustments.item_cart")
                }}
              </v-badge>
              <span v-else>
                <v-icon>mdi-cart</v-icon>&nbsp;{{
                  $t("stockAdjustments.item_cart")
                }}
              </span>
            </v-tab>
            <v-tabs-slider color="white"></v-tabs-slider>
          </v-tabs>
        </template>
      </v-toolbar>
    </v-card>
    <v-card-text>
      <v-tabs-items v-model="tabs">
        <v-tab-item value="product">
          <v-row class="mt-n1">
            <v-col
              cols="12"
              sm="2"
              md="2"
            >
              <v-text-field
                outlined
                dense
                :label="$t('search')"
                class=""
                v-model="options.search"
              ></v-text-field>
            </v-col>
            <v-col
              sm="2"
              md="2"
              class="pl-0"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.shelf_id"
                :items="shelves"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.shelf_id, 2)"
                :label="$t('shelf')"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_parent_id"
                :items="categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.category_parent_id, 3)"
                :label="$t('category')"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                v-model="options.category_id"
                :items="sub_categories"
                item-text="name"
                item-value="id"
                :loading="isLoadingSubCategories"
                :label="$t('sub_category')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listProducts"
            :loading="isLoadingProducts"
            item-key="id"
            class="elevation-4"
            :server-items-length="productsMeta.totalItems"
            :options.sync="options"
            :items-per-page="10"
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.qte_in_hub="{ item }">
              <span class="font-weight-medium">{{
                item.qte_in_hub ?? "-"
              }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                v-if="IsProductInCart(item)"
                color="success"
                @click="openOrderItemModal(item)"
                >Edit</v-btn
              >
              <v-btn
                small
                v-else
                color="#6100ea"
                @click="openOrderItemModal(item)"
                >ADD</v-btn
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="cart">
          <v-row class="mt-n1">
            <v-col
              sm="2"
              md="2"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                :items="shelves"
                v-model="selectedShelf"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="loadCategory(options.shelf_id, 2), filterProducts"
                :label="$t('shelf')"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                :items="categories"
                v-model="selectedCategory"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                @change="
                  loadCategory(options.category_parent_id, 3), filterProducts
                "
                :label="$t('category')"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
            >
              <v-autocomplete
                outlined
                clearable
                dense
                :items="sub_categories"
                v-model="selectedSubCategory"
                item-text="name"
                item-value="id"
                :loading="isLoadingSubCategories"
                @change="filterProducts"
                :label="$t('sub_category')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            :headers="cart_headers"
            item-key="name"
            :items="filteredProducts"
            :loading="loadingCart"
            class="elevation-0"
            show-expand
            single-expand
            :footer-props="{ 'items-per-page-options': [5, 10] }"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                color="#C6C6C6"
                @click="openOrderItemModal(item)"
              >
                $vuetify.icons.iconEditItem
              </v-icon>
              <v-icon @click="removeFromCart(item)">
                $vuetify.icons.iconDeleteItem
              </v-icon>
            </template>
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.real_qte="{ item }">
              <span>{{ calculatePhysicalQteSum(item) }}</span>
            </template>
            <template v-slot:item.theoretical_qte="{ item }">
              <span>{{ calculateTheoreticalQteSum(item) }}</span>
            </template>
            <template
              v-slot:item.data-table-expand="{ item, isExpanded, expand }"
            >
              <v-icon
                @click="expand(true)"
                v-if="item.lots?.length > 0 && !isExpanded"
                >mdi-chevron-down</v-icon
              >

              <v-icon
                @click="expand(false)"
                v-if="item.lots?.length > 0 && isExpanded"
                >mdi-chevron-up</v-icon
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table
                  class="my-5"
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("Batches") }}
                        </th>

                        <th class="text-left">
                          {{ $t("DLC") }}
                        </th>
                        <th class="text-left">
                          {{ $t("Real expiration date") }}
                        </th>
                        <th class="text-left">
                          {{ $t("Unit price HT") }}
                        </th>

                        <th class="text-left">
                          {{ $t("Theoretical stock") }}
                        </th>
                        <th class="text-left">
                          {{ $t("Real stock") }}
                        </th>
                        <th class="text-left">
                          {{ $t("Difference") }}
                        </th>
                        <th class="text-left">
                          {{ $t("Comment") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="lot in item.lots"
                        :key="lot.id"
                      >
                        <td>{{ lot.lot_number }}</td>
                        <td>{{ lot.theoretical_expiration_date }}</td>
                        <td>{{ lot.real_expiration_date }}</td>
                        <td>{{ lot.lot_price_ht }}</td>
                        <td>{{ lot.theoretical_qte }}</td>
                        <td>{{ lot.real_qte }}</td>
                        <td>{{ lot.gap_qte }}</td>
                        <td>{{ lot.gap_note }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
          <div class="mt-8 d-flex">
            <v-btn
              outlined
              small
              class="ml-auto primary--text"
              @click="cancel()"
              >{{ $t(cancel_button_text) }}</v-btn
            >
            <v-btn
              small
              :disabled="!stockAdjustment.products?.length"
              class="ml-2 primary"
              @click="submitAdjustment()"
              >{{ $t("stockAdjustments.request") }}</v-btn
            >
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <submit-adjustment-modal
      :isSubmitAdjustmentModalOpen="isSubmitAdjustmentModalOpen"
      :toggleSubmitAdjustment="toggleSubmitAdjustment"
      :currentStockAdjustment="stockAdjustment"
    ></submit-adjustment-modal>
    <product-modal
      v-if="isModalOpen"
      :isModalOpen="isModalOpen"
      :stockAdjustmentItem="currentStockItem"
      :toggleModal="() => toggleModal()"
    ></product-modal>
    <cancel-daraft-adjustment-stock-modal
      v-if="$admin.can('stockAdjustment-delete') && isDeleteModalOpen"
      :isDeleteModalOpen="isDeleteModalOpen"
      :stockAdjustment="currentAdjustment"
      :toggleDeleteModal="() => toggleDeleteModal()"
      :return_to_root="root_to_return"
    ></cancel-daraft-adjustment-stock-modal>
  </v-container>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import categoryService from "@/store/services/category-service";
import stockAdjustmentService from "@/store/services/stock-adjustment-service";
import debounce from "lodash/debounce";
import _ from "lodash";
export default {
  mixins: [validationRules],
  components: {
    ProductModal: () => import("./modals/ProductModal.vue"),
    SubmitAdjustmentModal: () => import("./modals/SubmitAdjustmentModal.vue"),
    CancelDaraftAdjustmentStockModal: () =>
      import("./modals/CancelDaraftAdjustmentStockModal"),
  },
  computed: {
    console: () => console,
    ...mapGetters({
      shelves: "categories/listShelves",
      isLoadingShelves: "categories/isLoadingCategories",
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingSubCategories: "categories/isLoadingCategories",
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
    }),

    stockAdjustment: {
      get() {
        return this.$store.state.stockAdjustments.stockAdjustment;
      },
      set(value) {
        this.$store.commit("stockAdjustments/EDITED_STOCK_ADJUSTMENT", value);
      },
    },
    filteredProducts() {
      let filteredProducts = this.stockAdjustment.products?.slice();

      if (this.selectedShelf) {
        filteredProducts = filteredProducts.filter((product) => {
          return (
            product.category.level === 1 ||
            (product.category.level === 2 &&
              product.category.parent_id === this.selectedShelf) ||
            (product.category.level === 3 &&
              product.category.parent.parent_id === this.selectedShelf)
          );
        });
      }
      if (this.selectedCategory) {
        filteredProducts = filteredProducts.filter((product) => {
          return (
            (product.category.level === 2 &&
              product.category.id === this.selectedCategory) ||
            (product.category.level === 3 &&
              product.category.parent_id === this.selectedCategory)
          );
        });
      }
      if (this.selectedSubCategory) {
        filteredProducts = filteredProducts.filter((product) => {
          return (
            product.category.level === 3 &&
            product.category.id === this.selectedSubCategory
          );
        });
      }

      return filteredProducts;
    },
  },
  watch: {
    async cleanList(value) {
      if (value == null) {
        await this.$store.dispatch("products/clean");
      }
    },
    options: {
      handler: debounce(async function () {
        await this.getProduct();
      }, 1000),
      deep: true,
    },
  },
  data() {
    return {
      is_valid: false,
      options: { itemsPerPage: 10, page: 1 },
      currentHubID: null,
      categories: [],
      sub_categories: [],
      cleanList: null,
      tabs: true,
      currentStockItem: null,
      isModalOpen: false,
      selectedShelf: null,
      selectedCategory: null,
      selectedSubCategory: null,
      loadingCart: false,
      isSubmitAdjustmentModalOpen: false,
      isDeleteModalOpen: false,
      currentAdjustment: null,
      cancel_button_text: "cancel",
      root_to_return: "stock-adjustments",
      headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "Product name",
          sortable: false,
          value: "name",
        },
        { text: "Product ID", value: "code" },
        { text: "SKU", value: "sku" },
        { text: "Stock", value: "qte_in_hub" },
        { text: "Actions", value: "actions" },
      ],
      cart_headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "name",
          sortable: false,
          value: "name",
        },
        { text: "theoretical stock", value: "theoretical_qte" },
        { text: "Real stock", value: "real_qte" },

        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },
    filterProducts() {
      this.loadingCart = false;
      this.filteredProducts();
      this.loadingCart = true;
    },
    async loadCategory(parent_id, level) {
      if (level == 2) {
        const categories = await categoryService.list({
          parent_id,
          level,
        });
        this.categories = categories;
        this.options.category_id = null;
      } else if (level == 3) {
        const sub_categories = await categoryService.list({
          level: 3,
          parent_id,
        });
        this.sub_categories = sub_categories;
      }
    },
    IsProductInCart(item) {
      return this.stockAdjustment?.products?.find(
        (stockAdjustmentItem) => stockAdjustmentItem.id == item.id
      )
        ? true
        : false;
    },
    async loadShelves() {
      try {
        await this.$store.dispatch("categories/listShelves");
      } catch (error) {
        if (error.response?.status == "401") {
          await this.$store.dispatch("logout");
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },

    removeFromCart(item) {
      this.$store.dispatch("stockAdjustments/removeFromCart", {
        product_id: item.id,
        stock_adjustment_id: this.stockAdjustment.id,
      });
    },
    openOrderItemModal(item) {
      this.validate();
      if (this.is_valid) {
        this.currentStockItem =
          this.stockAdjustment?.products?.find(
            (stockAdjustmentItem) => stockAdjustmentItem.id == item.id
          ) ??
          _.pick(
            item,
            "id",
            "product_id",
            "name",
            "code",
            "barcode",
            "image",
            "qte_in_hub"
          );
        this.isModalOpen = true;
      }
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    validate() {
      this.$refs.form.validate();
    },
    async loadHubs() {
      try {
        await this.$store.dispatch("hubs/list");
      } catch (error) {
        if (error?.message.includes("undefined")) {
          await this.$store.dispatch("alerts/error", "Error with data !!!");
          return;
        }
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    calculatePhysicalQteSum(product) {
      return product.lots.reduce((total, lot) => total + lot.real_qte, 0);
    },
    calculateTheoreticalQteSum(product) {
      return product.lots.reduce(
        (total, lot) => total + lot.theoretical_qte,
        0
      );
    },
    async init() {
      const id = this.$route.params.id;
      if (id) {
        this.cancel_button_text = "stockAdjustments.back";
      }
      await this.loadShelves();
      await this.loadHubs();
    },

    toggleSubmitAdjustment() {
      this.isSubmitAdjustmentModalOpen = !this.isSubmitAdjustmentModalOpen;
    },
    submitAdjustment() {
      this.toggleSubmitAdjustment();
    },
    cancel() {
      const id = this.$route.params.id;
      if (id) {
        this.$router.push("/" + this.root_to_return);
      }
      if (this.stockAdjustment?.id != null) {
        this.isDeleteModalOpen = true;
        this.currentAdjustment = this.stockAdjustment;
      } else {
        this.isDeleteModalOpen = false;
        this.currentAdjustment = null;
        this.$router.push("/" + this.root_to_return);
      }

      console.log("Where is the articles :", this.stockAdjustment);
    },
    async getProduct() {
      if (this.stockAdjustment.hub_id) {
        await this.$store.dispatch("products/list", {
          search: this.options.search,
          itemsPerPage: this.options.itemsPerPage,
          page: this.options.page,
          shelf_id: this.options.shelf_id,
          category_parent_id: this.options.category_parent_id,
          category_id: this.options.category_id,
          hub_id: this.stockAdjustment.hub_id,
          with_active_lots: true,
        });
      }
    },

    async handleWarehouseChange() {
      try {
        if (this.stockAdjustment?.products?.length > 0) {
          const resultSwal = await this.$swal({
            icon: "warning",
            text: this.$t("stockAdjustments.clear_cart_confirmation_message"),
            showConfirmButton: true,
            showCancelButton: true,
          });
          if (resultSwal.isConfirmed) {
            this.console.log("confirmed");
            this.updateAdjustment(this.stockAdjustment);
            this.getProduct();
          } else {
            this.stockAdjustment.hub_id = this.currentHubID;
            this.console.log("refused");
          }
        } else {
          if (this.stockAdjustment?.id) {
            this.updateAdjustment(this.stockAdjustment);
          }
          this.getProduct();
          this.currentHubID = this.stockAdjustment.hub_id;
        }
      } catch (error) {
        this.is_loading = false;
        this.console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    async handleUpdate() {
      try {
        if (this.stockAdjustment?.id) {
          this.updateAdjustment(this.stockAdjustment);
          this.getProduct();
        }
      } catch (error) {
        this.is_loading = false;
        this.console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    async updateAdjustment(adjustment) {
      try {
        if (adjustment.id) {
          await this.$store.dispatch("stockAdjustments/update", adjustment);
          this.$store.dispatch(
            "alerts/success",
            this.$t("updated_successfully")
          );
        } else {
          this.$store.dispatch("alerts/error", "Adjustment doesn't exist");
        }
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },

  async created() {
    this.$store.commit("stockAdjustments/EDITED_STOCK_ADJUSTMENT", {});
    this.$store.commit("products/CLEAN_LIST");
    await this.init();
    const id = this.$route.params.id;
    if (id) {
      const stockAdjustment = await stockAdjustmentService.show({
        id,
        include: "products,checkedBy",
      });
      this.$store.commit(
        "stockAdjustments/EDITED_STOCK_ADJUSTMENT",
        stockAdjustment
      );
      await this.getProduct();
      this.currentHubID = this.stockAdjustment.hub_id;
    }
  },
};
</script>
